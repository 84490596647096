/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { PageContentLayout } from '@layouts';
import { login } from '@store';
import { useConsolidateStoreOperations } from '@hooks';
import { signInWithGoogle } from 'config/firebase';
import { ButtonV2 } from '@components';
import { REDIRECT_URL_STORAGE_KEY } from '@constants';

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const { fetchUser } = useConsolidateStoreOperations();
  const dispatch = useDispatch();
  const { replace } = useRouter();

  const goToLastPage = () => {
    replace(localStorage.getItem(REDIRECT_URL_STORAGE_KEY) ?? '/');
    // remove the initially stored url.
    localStorage.removeItem(REDIRECT_URL_STORAGE_KEY);
  };

  useEffect(() => {
    if (localStorage.token) {
      goToLastPage();
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleSignIn = async (token) => {
    await dispatch(login(`${token}`));
    await fetchUser();
    setIsLoggedIn(true);
    goToLastPage();
  };

  const handleClick = () => {
    signInWithGoogle(handleSignIn);
  };

  return (
    <>
      <Head>
        <title>Login</title>
        <meta name="description" content="Instacar ledger" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {!isLoggedIn && (
        <PageContentLayout title="" disablePermissionGate isLogin>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100vh - 70px)',
            }}
          >
            <Box component="h2">Welcome back!</Box>
            <ButtonV2 onClick={handleClick}>Sign In</ButtonV2>
          </Box>
        </PageContentLayout>
      )}
    </>
  );
};

export default Login;
